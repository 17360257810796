import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import Typography from "../../Unknown/Typography";
import Grid from "../../Unknown/Grid";
import { Appointment } from "./types";
import useStyles from "./useStyles";
import messages from "./messages";

export interface AppointmentAddOnsListProps {
  appointment: Appointment;
}

const AppointmentAddOnsList: React.FC<AppointmentAddOnsListProps> = ({
  appointment,
}) => {
  const { labelRoot } = useStyles();
  const { formatMessage } = useIntl();

  const addOnNames = useMemo(() => {
    const appointmentAddOns = appointment.addOns.map((addOn) => addOn.name);

    const packageAddOns =
      appointment.packages
        .map((pkg) => {
          return pkg.addOns.map((addOn) => {
            return formatMessage(messages.perStudyAddOn, {
              addOn: addOn.name,
              study: pkg.name,
            });
          });
        })
        .flat() || [];

    const offeringAddOns =
      appointment.offerings
        .map((offering) => {
          const sideLabel = offering.side
            ? messages[offering.side as keyof typeof messages]
            : null;
          const offeringName = sideLabel
            ? `${offering.name} (${formatMessage(sideLabel)})`
            : offering.name;

          return offering.addOns?.map((addOn) => {
            return formatMessage(messages.perStudyAddOn, {
              addOn: addOn.name,
              study: offeringName,
            });
          });
        })
        .flat() || [];

    return [...appointmentAddOns, ...packageAddOns, ...offeringAddOns];
  }, [
    appointment.addOns,
    appointment.offerings,
    appointment.packages,
    formatMessage,
  ]);

  if (!addOnNames.length) {
    return null;
  }

  return (
    <Grid item xs={12} lg={6}>
      <Typography className={labelRoot}>
        {formatMessage(messages.labelAddOns)}
      </Typography>
      <Typography variant="body2">{addOnNames.join(", ")}</Typography>
    </Grid>
  );
};

export default AppointmentAddOnsList;
