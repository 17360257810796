import React, { FC, useMemo, ChangeEvent, useCallback } from "react";
import CheckboxGroup from "../../Unknown/CheckboxGroup";
import Box from "../../Unknown/Box";
import { AddOn, CenterConfig, CenterServicesState } from "./types";
import parseAddOn from "./parseAddOn";

export type ServiceOption = {
  groupId: string;
  value: string;
  label: string;
  price: string;
  description: string;
  discountPrice?: string;
  isDisabled?: boolean;
  addOnIds?: string[];
  metadata?: Record<string, string | boolean | number | null>;
};

export interface SelectStudyAddOnsFormProps {
  currentServiceId: string;
  addOns: AddOn[] | null;
  centerConfig: CenterConfig;
  isSubmitting: boolean;
  selectedCenterServices: CenterServicesState;
  setSelectedCenterServices: React.Dispatch<
    React.SetStateAction<CenterServicesState>
  >;
}

const SelectStudyAddOnsForm: FC<SelectStudyAddOnsFormProps> = ({
  addOns,
  centerConfig,
  isSubmitting,
  selectedCenterServices,
  setSelectedCenterServices,
  currentServiceId,
}) => {
  const options = useMemo(() => {
    if (!centerConfig || !addOns?.length) return [];

    const { defaultCurrencyCode } = centerConfig;

    const centerService = selectedCenterServices.find(
      (service) => service?.value === currentServiceId,
    );

    return addOns
      .filter((addOn) => addOn.appliesTo === "study")
      .map((addOn) => {
        const checked = !!centerService?.addOnIds?.some(
          (addOnId) => addOnId === addOn.id,
        );

        return parseAddOn({
          addOn,
          currencyCode: defaultCurrencyCode,
          checked,
          offeringsCount: centerService?.studyIds?.length || 0,
        });
      });
  }, [addOns, centerConfig, currentServiceId, selectedCenterServices]);

  const onChecked = useCallback(
    (addOnId?: string): void => {
      if (!centerConfig || !addOns?.length || !addOnId) return;
      setSelectedCenterServices((prev) => {
        const selectedService = prev.find(
          (service) => service?.value === currentServiceId,
        );

        if (selectedService) {
          const updatedAddOnIds = selectedService.addOnIds
            ? [...selectedService.addOnIds, addOnId]
            : [addOnId];

          return prev.map((service) =>
            service?.value === currentServiceId
              ? { ...service, addOnIds: updatedAddOnIds }
              : service,
          );
        }

        return prev;
      });
    },
    [addOns, centerConfig, setSelectedCenterServices, currentServiceId],
  );

  const onUnchecked = useCallback(
    (addOnId?: string) => {
      if (!addOnId) return;

      setSelectedCenterServices((prev) => {
        const updatedServices = prev.map((service) => {
          if (service?.value === currentServiceId) {
            return {
              ...service,
              addOnIds: service.addOnIds?.filter((id) => id !== addOnId) || [],
            };
          }
          return service;
        });

        return updatedServices;
      });
    },
    [setSelectedCenterServices, currentServiceId],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const actionFunc = checked ? onChecked : onUnchecked;
      actionFunc(e?.target?.value);
    },
    [onChecked, onUnchecked],
  );

  if (!centerConfig || !addOns?.length) return null;

  return (
    <Box mt={2}>
      <CheckboxGroup
        options={options}
        onChange={onChange}
        disabled={isSubmitting}
      />
    </Box>
  );
};

export default SelectStudyAddOnsForm;
