import Big from "big.js";
import { useCallback, useEffect } from "react";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";

import useDataState from "../../../hooks/useDataState";
import useSupabase from "../../../hooks/useSupabase";
import { CenterConfig } from "./types";

export type SupabaseBookingOffering = Pick<
  DatabaseRow<"center_offerings">,
  | "id"
  | "name"
  | "description"
  | "deposit_amount"
  | "price"
  | "scan_side"
  | "duration_minutes"
>;

const supabaseBookingOfferingsRequest = `
id,
name,
description,
deposit_amount,
price,
scan_side,
duration_minutes
`;

export type BookingOffering = {
  id: string;
  name: string;
  description: string;
  depositAmount: number;
  price: number;
  scanSide: "single" | "dual" | null;
  durationMinutes: number;
  discountPrice: number;
  addOnIds: string[];
  studyIds: string[];
};

export const parseOffering = (
  offering: SupabaseBookingOffering,
  defaultOfferingDurationMinutes: number,
  discountAmount: number,
): BookingOffering => {
  const {
    id,
    name,
    description,
    deposit_amount,
    price,
    scan_side,
    duration_minutes,
  } = offering;

  const durationMinutes =
    duration_minutes !== null
      ? duration_minutes
      : defaultOfferingDurationMinutes;

  const discountPrice = discountAmount
    ? Big(price).minus(discountAmount).toNumber()
    : price;

  return {
    id,
    name,
    description,
    depositAmount: deposit_amount,
    price,
    scanSide: scan_side,
    durationMinutes,
    discountPrice,
    addOnIds: [],
    studyIds: [id],
  };
};

const useOfferings = ({ centerConfig }: { centerConfig?: CenterConfig }) => {
  const supabase = useSupabase();

  const [dataState, updateDataState] = useDataState<BookingOffering[]>();

  const getOfferingsRequest = useCallback(async () => {
    try {
      if (!centerConfig) return;

      const { id, defaultOfferingDurationMinutes, discountAmount } =
        centerConfig;

      const visibility = ["private", "public"];

      updateDataState({ status: "loading" });

      const response = await supabase
        .from<DatabaseRow<"center_offerings">>("center_offerings")
        .select(supabaseBookingOfferingsRequest)
        .is("center_package_id", null)
        .in("visibility", visibility)
        .eq("center_id", id);

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (response.data) {
        const parsedOfferings = response.data.map((offering) =>
          parseOffering(
            offering,
            defaultOfferingDurationMinutes,
            discountAmount,
          ),
        );

        updateDataState({ status: "success", data: parsedOfferings });
      }
    } catch (err) {
      updateDataState({ status: "error", error: (err as Error).message });
    }
  }, [centerConfig, supabase, updateDataState]);

  useEffect(() => {
    getOfferingsRequest();
  }, [getOfferingsRequest, centerConfig]);

  return dataState;
};

export default useOfferings;
