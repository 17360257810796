import supabaseClient from "../../../common/supabaseClient";
import { appointmentRequest } from "./constants";
import { Appointment } from "./types";

const getAppointmentData = async (appointmentId: string) => {
  const { data, error } = await supabaseClient
    .from<Appointment>("appointments")
    .select(appointmentRequest)
    .eq("id", appointmentId)
    .is(
      "appointment_offerings.appointment_package_id" as keyof Appointment,
      null,
    )
    .is("appointment_add_ons.appointment_package_id" as keyof Appointment, null)
    .is(
      "appointment_add_ons.appointment_offering_id" as keyof Appointment,
      null,
    )
    .not("appointment_payments.confirmed_at" as keyof Appointment, "is", null)
    .limit(1)
    .limit(1, { foreignTable: "center.center_configs" })
    .order("id", {
      foreignTable: "appointment_packages.appointment_offerings",
      ascending: false,
    })
    .order("id", {
      foreignTable: "appointment_offerings",
      ascending: false,
    })
    .order("created_at", {
      foreignTable: "appointment_refunds",
      ascending: false,
    })
    .order("created_at", {
      foreignTable: "appointment_notes",
      ascending: false,
    });

  if (error) throw new Error(error.message);

  if (!data.length) return null;

  return data[0];
};

export default getAppointmentData;
